module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Urlaub mit Herz im Harz","short_name":"Mit Herz im Harz","start_url":"/","background_color":"#752f1d","theme_color":"#752f1d","display":"minimal-ui","icon":"src/images/mitherzimharz-logo-500-lightbrown.png","icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"72ff90f6df3f0d1f4ee4860bc90573dc"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
